import styled from 'styled-components';
import { font } from '../../styles/atoms/typography';

const DropdownStyles = styled.div`
  display: none;

  @media (max-width: 552px) {
    display: block;
  }

  .dropdown-filter {
    padding: 0;

    & button {
      ${font('text', 'lg', '400')}
      padding: 4px 6px;
      transition: all 0.2s;
      cursor: pointer;
      border: none !important;
      background-color: transparent !important;
      color: var(--primary-color) !important;

      &.dropdown-toggle::after {
        margin-left: 6px;
        vertical-align: middle;
      }

      &:focus {
        box-shadow: none !important;
        border: none !important;
        background-color: transparent !important;
      }
    }

    &.isMedium button,
    &.isMedium a.dropdown-item {
      font-size: 18px !important;
    }

    &.isBold button {
      font-weight: 700;
    }

    & a.dropdown-item {
      font-size: 14.4px;
      padding: 8px 24px;

      &:hover {
        color: var(--primary-color);
        background-color: #e9f2ff;
      }
    }

    .dropdown-menu {
      padding: 8px;
    }
  }
`;

export default DropdownStyles;
